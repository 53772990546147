/******** Keep scrollbar so header doesn't jump **********/
html { overflow-y: scroll; } 

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Josefin+Sans:wght@700&display=swap');

canvas {
	position: fixed;
	background-color: black;
	top: 0;
	left: 0;
	width: 100vw;
}

:root { 
	--dark-bg: rgba(15, 15, 15, 0.95);
	--spacing: 50px;
	font-family: 'Josefin Sans', sans-serif;
	font-weight: 100;
	font-style: normal;
	color: white;
}

.linkbox {
	width: 100%;
	overflow: hidden;
}

.link-button {
	width: 25%;
	float: left;
	text-align: center;
	text-decoration: none;
	font-family: 'Audiowide', cursive;
	font-size: 30px;
	color: #888888;
	background-color: #000066;
}

a {
	text-decoration: none;
	font-family: 'Audiowide', cursive;
	font-size: 20px;
	color: #888888;
}

html {
	scroll-behavior: smooth;
}

.anchor-offset {
	display: block;
	height: 150px;
	margin-top: 150px;
	visibility: hidden;
}

header {
	width: 100%;
	height: 150px;
	min-width: 400px;
	overflow: hidden;
	position: fixed;
	z-index: 99;
	background: var(--dark-bg);
	grid-column: 1/span 12;
	font-size: 2.5rem;
	padding: 1rem;
}

.content-outer {
	width: 100%;
	min-height: 100%;		/* Push footer to bottom */
	z-index: 98;
	position: absolute;
}

.content {
	margin-top: 200px;			/* Header buffer */
	margin-bottom: 100px;		/* Footer buffer */
	padding: 20px 0px;
	display: grid;
	grid-template-columns: repeat(12, 1fr);
}

h1 {
	text-align: center;
	font-size: 60px;
	white-space: nowrap;
}

h1, h2, h3, blockquote {
	font-family: 'Audiowide', cursive;
	font-weight: 700;
	font-style: normal;
}

p {
	margin-top: 10px;
}

section {
	grid-column: 1/span 12;
	padding: 1rem;
	background: var(--dark-bg);
	font-size: 1.25rem;
	line-height: 1.5;
	margin-bottom: var(--spacing);
}

blockquote {
	margin: 0;
	padding: 0;
	grid-column: 2/span 9;
	margin-bottom: var(--spacing);
}

blockquote p {
	color: black;
	background-color: white;
	font-size: 4rem;
	display: inline;
	line-height: 1;
}

.left {
	grid-column: 2 / span 7;
}

.right {
	grid-column: 5 / span 7;
}

/* Switch left + right to narrow if screen is narrow */
.narrow {
	grid-column: 1 / span 12;
}


/******************/
/*Social media buttons - https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_social_media_buttons */

.socialm {
	height:20px;
  text-align: center;
    font-size: 16px;
}

.fa {
  padding: 2px;
  width: 20px;
  height: 18px;
  text-align: center;
  text-decoration: none;
  margin: 2px 2px;
}

.fa:hover {
    opacity: 0.7;
}

.fa-facebook {
  background: #3B5998;
  color: white;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-youtube {
  background: #bb0000;
  color: white;
}

.fa-skype {
  background: #00aff0;
  color: white;
}

.fa-github {
  background: #04122b;
  color: white;
}

.fa-twitter {
  background: #00acee;
  color: white;
}


.fa-envelope-square {
  background: white;
  color: black;
}


.custom-sm-odysee {
    background:none;
    color:orange;
    background-color:white;
    height: 18px;
    width: 18px;
 	position: relative;
  	top: 1.5px;
}

.custom-sm-odysee:hover{
    background:#0000ff; 
    background-color:blue;
}

/********* Tech stack *********/

.techstack {
	text-align: center;
	font-size: small;
}

img.techstack {
	width: 50px;
	height: 50px;
}

.techstack li {
	margin: 2px;
	display: inline-block;
	list-style-type: '';
  	padding-inline-start: 0ch;
}

/******** Popins **********/
/*
 * Used on techstack images.
 */

.popin {
  opacity: 0;
  transition: opacity 2s;
}

.popin.animate {
  opacity: 1;
}

/******** Unnumbered lists **********/

ul {
	list-style-position: inside;
}

li {
  list-style-type: '👉 ';
  padding-inline-start: 2ch;
}

/********* Screenshot set *********/
/*
 * Brave images on homepage.
 */

.screenshots {
	text-align: center;
	font-family: 'Audiowide', cursive;
	font-size: 50%;
	margin-top: 0;
}

.screenshots img {
	margin-top: 20px;
	width: 325px;
	height: 160px;
}

.screenshots li {
	margin: 2px;
	display: inline-block;
}

/********* Company logos *********/

.companies {
	margin-left: 80px;
	max-width: 600px;
  	background-color: #bbbbbb;
}

.companies img {
	width: 80px;
	height: 60px;
	margin: 12px;
}


/********* More about links *********/

.more {
	text-align: right;
	color: white;
}

.more-button {
	font-family: 'Audiowide', cursive;
	font-size: 30px;
	color: #888888;
	background-color: #000066;
}

/*************/

.bravepic {
	max-width: 440px; 
	height: auto; 
	padding-top: 20px; 
	padding-left: 20px; 
	padding-right: 20px; 
	padding-bottom: 20px; 
	text-align: center;	
}

.f_right {
	float: right;
	text-align: right; 
}

.f_left {
	float: left;
	text-align: left; 
}

.bravepic p {
	font-family: 'Audiowide', cursive;
	font-size: 50%;
	margin-top: 0;
}

.bravepic img {
	width: 100%;
	height: 200px;
}

/*********** Video styles *************/

.vidpanel {
	display: inline-block;
    margin: 18px 18px 0px !important;
    position: relative !important;
    width: 210px !important;
    height: 118px !important;
    background-size: 210px !important;
    filter: brightness(95%) !important;
}

.vidtext {
	font-size: 50%;
	margin-top: 0;
	color: white;
}

.vidtime {
    position: absolute;
    bottom: 0px !important;
    right: 0px !important;
    color: rgb(255, 255, 255) !important;
    background-color: rgb(17, 17, 17) !important;
    opacity: 0.8 !important;
    font-family: Roboto, Arial, sans-serif !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    margin: 4px !important;
    padding: 2px 4px !important;
}

.play-btn {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  margin-top: auto;
  padding: 19px 60px;
}

.play-btn__svg {
  transition: 1s; 
  fill:#ffffff; 
  opacity: .0;
}

.play-btn:hover .play-btn__svg {
  fill: #000000;
  opacity: 1;
}

.vidimage {
	position: absolute;
	width: 210px;
	height: 118px;
	padding: 0px;
	float: center;
	border-radius: 8px;
}

/************ Footer ****************/
footer {
	width: 100%;
	height: 80px;
	min-width: 400px;
	overflow: hidden;
	position: absolute;
	bottom: 0;
	margin: 0px;
	z-index: 99;
	background: var(--dark-bg);
	font-family: "Open Sans";
	padding-top: 10px;
	text-align: center;
}

/************ Contact form ****************/

.contact-form {
		display: grid;
	grid-template-areas: 'name email' 'subject subject' 'messageHeader messageHeader'
		'message message' 'sendButton sendButton';
	gap: 5px;
	padding: 10px;
	width: 100%;
	color: #61DAFB;
	grid-template-rows: auto auto auto 200px auto;
	grid-template-columns: 50% 50%;
}

.contact-form-name {
	grid-area: name;
	width: 100%;
}

.contact-form-email {
	grid-area: email;
	width: 100%;
}

.contact-form p {
	float: left;
}

.contact-form span {
    display: block;
    overflow: hidden;
    padding-left:10px;
    height: 100%;
}

.contact-form input {
	width: 100%;
    height: 100%;
	font-family: 'Audiowide', cursive;
	font-size: 20px;
	color: #888888;
	background-color: #222222;
	border: none;
}

.contact-form input:-webkit-autofill,
.contact-form input:-webkit-autofill:hover, 
.contact-form input:-webkit-autofill:focus, 
.contact-form input:-webkit-autofill:active{
    -webkit-transition: "color 999999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 999999s;
}

.contact-form textarea {
	width: 100%;
    height: 100%;
    padding: 5px;
	font-family: 'Audiowide', cursive;
	font-size: 20px;
	color: #888888;
	background-color: #222222;
	border: none;
	overflow-y: scroll;
	resize: none;
}

.contact-form-error input {
	background-color: red;
}
.contact-form-error textarea {
	background-color: red;
}

.contact-form-subject {
	grid-area: subject;
	width: 100%;
}

.contact-form-messageHeader {
	grid-area: messageHeader;
	width: 100%;
}

.contact-form-message {
	grid-area: message;
	width: 100%;
}


.contact-form textarea::-webkit-scrollbar {
    width: 1em;
}

.contact-form textarea::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.contact-form-submitButton {
	grid-area: sendButton;
	width: 100%;
}

.contact-form-submitButton button {
	font-family: 'Audiowide', cursive;
	font-size: 30px;
	color: #888888;
	background-color: #000066;
	float: right;
}